import * as React from 'react'
import _ from 'lodash'
import { FaExternalLinkAlt, FaMapMarker } from 'react-icons/fa'
import { MdLocationOn, MdAccessTime } from 'react-icons/md'
import dateFns from 'date-fns'

import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '@components/Layout'
import UI, { Box, H4, H3, H2 } from '@components/UI'
import { Query } from '@generated/graphql'

import { Theme } from '../style/theme'

const Date = styled(H4).attrs({
  pb: 1,
  color: 'black'
})`
  text-transform: uppercase;
`

const CityCountry = styled(H4).attrs({
  color: 'primary',
  pb: 1
})`
  text-transform: uppercase;
`

const Time = styled(H4).attrs({
  color: 'dark',
  pb: 1
})`
  text-transform: uppercase;
`

const Description = styled(H4).attrs({
  mb: 1,
  color: 'dark'
})``

const Link = styled(UI.Link).attrs({
  fontFamily: 'sans',
  target: '_blank',
  rel: 'noopener noreferrer',
  color: 'dark',
  pt: 2,
  display: 'block'
})``

const EventBox = styled(Box).attrs(({ pb }) => ({
  pb: pb || 4,
  mb: 4,
  borderStyle: 'solid',
  borderWidth: 0,
  borderTop: 0,
  borderX: 0,
  borderColor: 'gray'
}))``

const SongkickTitle = styled(H2).attrs({
  color: 'white',
  mb: 1
})``

const SongkickSubtitle = styled(H3).attrs({
  color: 'black'
  // fontStyle: 'italic'
})``

const SongkickButton = styled(Box).attrs({
  backgroundColor: '#f94a77',
  padding: 3,
  mb: 5,
  maxWidth: '470px'
})`
  border-radius: 16px;
`

// <Link href={link || null}>
// STODO: don't display time
const Event = ({ event: { dateObj, time, venue, city, country, link } }) => (
  <Box>
    <EventBox>
      <Date>{dateFns.format(dateObj, 'ddd, D MMM YYYY')}</Date>
      {city ? (
        <CityCountry>{`${city}, ${country}`}</CityCountry>
      ) : (
        <CityCountry>{`${country}`}</CityCountry>
      )}
      {time && (
        <Time>
          <MdAccessTime />
          {` ${dateFns.format(dateObj, 'h:mm A')}`}
        </Time>
      )}
      <Box>
        <Description>
          <MdLocationOn style={{ verticalAlign: 'left' }} />
          {` ${venue}`}
        </Description>
      </Box>

      {link ? (
        <Link href={link || null}>
          {/*<FaExternalLinkAlt />*/}
          TICKETS:
          {` ${link}`}
        </Link>
      ) : null}
    </EventBox>
  </Box>
)

const formatEvents = events =>
  _.chain(events)
    .map(({ date, time, venue, city, country, link }) => ({
      dateObj: dateFns.parse(`${date}T${time}`),
      time,
      venue,
      city,
      country,
      link
    }))
    .partition(({ dateObj }) => dateFns.isPast(dateFns.addDays(dateObj, 1)))
    .value()

const Events = ({ data }: { data: Query }) => {
  const events = formatEvents(
    data.allMarkdownRemark.nodes[0].frontmatter.events
  )
  const eventsPast = _.orderBy(events[0], 'dateObj', 'desc')
  const eventsUpcoming = _.orderBy(events[1], 'dateObj', 'asc')

  const cvEvents = _.chain(data.allMarkdownRemark.nodes[0].frontmatter.events)
    .orderBy('date', 'desc')
    .map(ev => {
      return `${dateFns.getYear(ev.date)}\t\t\t\t${ev.venue} (${ev.city}, ${
        ev.country
      })`
    })
    .value()

  console.log('___ events', cvEvents.join('\n'))

  return (
    <Layout title="Events">
      <Link
        href="https://accounts.songkick.com/artists/8797829/trackings/signup?source_product=widget&success_url=https%3A%2F%2Fwww.songkick.com%2Fpostsignup&utm_source=11593&utm_medium=partner&utm_campaign=track_cta&utm_content=8797829"
        target="_blank"
        rel="noopener noreferrer"
      >
        <SongkickButton>
          <SongkickTitle>Subscribe to our tour dates on Songkick</SongkickTitle>
          <SongkickSubtitle>
            Get an email when we have a show near you!
          </SongkickSubtitle>
        </SongkickButton>
      </Link>
      <EventBox pb={2}>
        <H2>Upcoming Events</H2>
      </EventBox>
      {_.map(eventsUpcoming, event => {
        return <Event event={event} />
      })}
      <EventBox pb={2}>
        <H2 pt={5}>Past Events</H2>
      </EventBox>
      {_.map(eventsPast, event => (
        <Event event={event} />
      ))}
    </Layout>
  )
}

export default Events

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content.events/" } }
    ) {
      nodes {
        frontmatter {
          events {
            date
            time
            venue
            city
            country
            link
          }
        }
      }
    }
  }
`
